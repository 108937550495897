<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";

export default {
  locales: {
    pt: {
      "Choose a value": "Escolha um valor",
      "Or enter the amount of your donate": "Ou digite o valor da sua doação",
      "Value is required.": "Valor é obrigatório",
      "New Donate": "Nova Doação",
      Rules: "Regras",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Status",
      Payment: "Pagamento",
      Details: "Detalhes",
      Pending: "Pendente",
      Approved: "Aprovado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "Choose a value": "Elija un valor",
      "Or enter the amount of your donate":
        "Ou introduzca la cantidad de su donación",
      "Value is required.": "Se requiere valor.",
      "New Donate": "Nueva Donar",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Estado",
      Payment: "Pago",
      Details: "Detalles",
      Pending: "Pendiente",
      Approved: "Aprobado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: {
        contract: false,
      },

      plan: this.$route.params.plan,

      contract: {
        alert: { type: "", message: "" },
        plan: null,
        price: "0.00",
        value: "0.00",
        promotions: [],
        promotion: 0,
      },

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    contract: {
      value: { required },
    },
  },
  methods: {
    getPlan() {
      if (this.plan) {
        api.get("plans/" + this.plan).then((response) => {
          if (response.data.status == "success") {
            this.contract.plan = response.data.plan;
            this.contract.value = this.$options.filters.currency(response.data.plan.value.min);
          }
        });
      }
    },
    newContract() {
      this.$v.$touch();

      if (this.plan && this.contract.value) {
        this.loading.contract = true;

        api
          .post("contracts", {
            plan: this.plan,
            value: this.contract.value,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.contract.value = "0.00";
              this.$v.$reset();

              this.contract.alert.type = "alert-success";
              this.contract.alert.message = response.data.message;

              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.contract.alert.type = "alert-danger";
              this.contract.alert.message = response.data.message;
            }

            this.loading.contract = false;
          });
      }
    },
  },
  mounted() {
    this.getPlan();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Novo Contrato") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card" v-if="contract.plan">
          <div class="card-body">
            Você escolheu o plano <strong>{{ contract.plan.name }}</strong
            ><br />
            De {{ contract.plan.value.min | currency }} até
            {{ contract.plan.value.max | currency }}
          </div>
        </div>
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="newContract">
              <div
                v-if="contract.alert.message"
                :class="'alert ' + contract.alert.type"
              >
                {{ t(contract.alert.message) }}
              </div>
              <b-form-group
                id="value"
                :label="t('Digite o valor que deseja')"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="contract.value"
                  v-money="money"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.contract.value.$error }"
                ></b-form-input>
                <div v-if="$v.contract.value.$error" class="invalid-feedback">
                  <span v-if="!$v.contract.value.required">{{
                    t("Value is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="
                    loading.contract == true || this.contract.value === '$0.00'
                  "
                  type="submit"
                  variant="default"
                >
                  {{ t("Novo Contrato") }}
                  <b-spinner
                    v-if="loading.contract"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>